<script>
import {required, email} from "vuelidate/lib/validators";
import HttpRequest from "@/http/HttpRequest"
import Preference from "@/data/Preference"

import Vue from 'vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(Loading);

let httpRequest = new HttpRequest()
let preference = new Preference()

export default {
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      logo: "",
      inputType: "password",
      iconClass: "ri-eye-off-line",
      subDomain : (window.location.hostname).split('.')
    };
  },
  computed: {

    hasNoSubdomain() {
      return !((window.location.hostname).split('.').length > 1);
    },
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  validations: {
    email: {required, email},
    password: {required}
  },
  methods: {
    togglePasswordVisibility() {
      this.inputType = this.inputType === "password" ? "text" : "password";
      this.iconClass =
          this.inputType === "password" ? "ri-eye-off-line" : "ri-eye-line";
    },
    getWorkSpaceDetails() {
      let _this = this
      _this.isLoading = true
      return httpRequest.getWorkspaceData().then(function (response) {
        _this.isLoading = false
        if (response.success) {
          _this.logo = response.data.company_logo
          preference.setData(Preference.KEY_COMPANY_LOGO_URL, response.data.company_logo)
        }
      })
    },
    // Try to log the user in with the username
    // and password they provided.
    doLogin() {

      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;

        // All right: Go for login api request
      } else {

        let _this = this

        // Init loader
        let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          onCancel: this.onCancel,
          backgroundColor: "#ffffff",
          loader: "dots"

        });

        // Init sweet alert2
        let swal = this.$swal

        _this.storeClientUniqueId()

        let clientID = preference.getData(Preference.KEY_CLIENT_ID)

        httpRequest.login(this.email.toLowerCase(), this.password, clientID).then(function (response) {
          loader.hide()
          if (response.success) {
            preference.setProfileData(response.data)
           // if(response.data.image)
           // {
           //   let parts = response.data.image.split('/');
           //   let filename = parts[parts.length - 1];
           //   //return Constants.AWS_FILE_BASE_URL + image
           //   httpRequest.generateImageUrl(filename).then(function (response) {
           //     if (response.success) {
           //       preference.setData(Preference.KEY_IMAGE,  response.data.URL)
           //     }
           //   })
           // }
            _this.$router.push({path: "/"});
          } else {
            swal(response.message);
          }

        })
      }

    }, storeClientUniqueId() {
      let nav = window.navigator
      let screen = window.screen
      let guid = nav.mimeTypes.length
      guid += nav.userAgent.replace(/\D+/g, '')
      guid += nav.plugins.length
      guid += screen.height || ''
      guid += screen.width || ''
      guid += screen.pixelDepth || ''

      preference.setData(Preference.KEY_CLIENT_ID, guid)
    }
  },
  async created() {
    document.body.classList.add("auth-body-bg");
    this.email = this.$route.query.id
    await this.getWorkSpaceDetails()
  },
};

</script>

<template>
  <!--<div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="form-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/workany-logo.png" height="90" width="90" alt="WorkAny"/>
                          </a>
                        </div>

                        <h4 class="font-size-22 mt-4">WorkAny</h4>
                        <p class="text-muted">Welcome! Please Sign in to continue.</p>
                      </div>

                      <b-alert
                          variant="danger"
                          class="mt-3"
                          v-if="notification.message"
                          show
                          dismissible
                      >{{ notification.message }}
                      </b-alert>

                      <div class="p-2 mt-5">
                        <form class="form-horizontal" @submit.prevent="doLogin">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="email">Email</label>
                            <input
                                type="email"
                                v-model="email"
                                class="form-control"
                                id="email"
                                placeholder="Enter email"
                                :class="{ 'is-invalid': submitted && $v.email.$error }"
                            />
                            <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                              <span v-if="!$v.email.required">Email is required.</span>
                              <span v-if="!$v.email.email">Please enter valid email.</span>
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="userpassword">Password</label>
                            <input
                                v-model="password"
                                type="password"
                                class="form-control"
                                id="userpassword"
                                placeholder="Enter password"
                                :class="{ 'is-invalid': submitted && $v.password.$error }"
                            />
                            <div
                                v-if="submitted && !$v.password.required"
                                class="invalid-feedback"
                            >Password is required.
                            </div>
                          </div>

                          <div class="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                class="custom-control-input"
                                id="customControlInline"
                            />
                            <label
                                class="custom-control-label"
                                for="customControlInline"
                            >Remember me</label>
                          </div>

                          <div class="mt-4 text-center">
                            <button
                                class="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                            >Log In
                            </button>
                          </div>

                          <div class="mt-4 text-center">
                            <router-link tag="a" to="/forgot-password" class="text-muted">
                              <i class="mdi mdi-lock mr-1"></i> Forgot your password?
                            </router-link>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          Don't have an account ?
                          <router-link
                              tag="a"
                              to="/register"
                              class="font-weight-medium text-primary"
                          >Register
                          </router-link>
                        </p>
                        <p>
                          © 2021 WorkAny. Crafted by W3 Engineers Ltd.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8 d-none d-md-block">
            <div class="min-vh-100 d-flex align-items-center">
              <div class="w-100">
                <h4 class="font-size-28 text-center">Spend less time tracking and more time growing.</h4>
                <p class="text-center">The all-in-one work time tracker for managing field or remote teams.</p>
                <div class="d-flex justify-content-center">
                  <img src="@/assets/images/bg/home.jpg" height="100%" width="70%" alt="logo"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>-->


  <div class="container-fluid p-0">
    <div class="row no-gutters">
      <div class="col-lg-5 col-xl-4" style="background-color: #f1f5f7">
        <div class="form-page-content p-4 d-flex align-items-center flex-column justify-content-center min-vh-100">
          <div class="text-center">
            <div>
              <a href="/" class="logo">
                <img src="@/assets/images/workany-v-logo.png" height="130" alt="WorkAny"/>
              </a>
            </div>

            <hr class="mt-3"/>

            <div v-if="logo" class="mt-2">
              <a href="/" class="logo">
                <img :src="logo" height="70" alt="Company Logo"/>
              </a>
            </div>
            <h4 class="font-size-18 mt-3 mt-md-4">Welcome! Please Sign in to continue.</h4>
          </div>

          <b-alert
              variant="danger"
              class="mt-3"
              v-if="notification.message"
              show
              dismissible
          >{{ notification.message }}
          </b-alert>

          <div class="p-2 mt-2 w-100" style="max-width: 440px">
            <form class="form-horizontal" @submit.prevent="doLogin">
              <div class="form-group auth-form-group-custom mb-3 mb-md-4">
                <i class="ri-mail-line auti-custom-input-icon customs-text-color"></i>
                <label for="email">Email</label>
                <input
                    type="email"
                    v-model="email"
                    @input="email = email.toLowerCase()"
                    class="form-control"
                    id="email"
                    placeholder="Enter your email"
                    :class="{ 'is-invalid': submitted && $v.email.$error }"
                />
                <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                  <span v-if="!$v.email.required">Email is required.</span>
                  <span v-if="!$v.email.email">Please enter valid email.</span>
                </div>
              </div>

              <div class="form-group auth-form-group-custom mb-3 mb-md-4">
                <i
                    class="ri-lock-2-line auti-custom-input-icon customs-text-color"

                ></i>
                <label for="userpassword">Password</label>
                <input
                    v-model="password"
                    :type="inputType"
                    maxlength="30"
                    class="form-control"
                    id="userpassword"
                    placeholder="Enter your password"
                    :class="{ 'is-invalid': submitted && $v.password.$error }"
                />

                <i class="auti-custom-input-icon-i customs-text-color" :class="iconClass" style="  width : 15px"
                   @click="togglePasswordVisibility"></i>

                <div
                    v-if="submitted && !$v.password.required"
                    class="invalid-feedback"
                >
                  Password is required.
                </div>
              </div>

              <div class="mb-2 mb-md-4 text-center">
                <button class="btn btn-primary w-md waves-effect waves-light customs-bg-color" type="submit">LOG IN
                </button>
              </div>

              <div class="text-center">
                <router-link tag="a" to="/forgot-password" class="text-muted link-hover">
                  <i class="mdi mdi-lock mr-1"></i> Forgot your password?
                </router-link>
              </div>
            </form>
          </div>

          <div>
            <p  v-if="!hasNoSubdomain" >
              If you don't have an account, contact your workspace({{subDomain[0]}}) admin.
              <router-link
                  v-if="hasNoSubdomain"
                  tag="a"
                  to="/register"
                  class="font-weight-medium text-primary"
              >Register
              </router-link>
            </p>
            <p  v-if="hasNoSubdomain" >
              Don't have an account ?
              <router-link
                  v-if="hasNoSubdomain"
                  tag="a"
                  to="/register"
                  class="font-weight-medium text-primary"
              >Register
              </router-link>
            </p>
          </div>
          <div class="mt-3 mt-md-4 text-center position-absolute fixed-bottom">
            <div style="border-bottom: 1px solid #ddd "></div>
            <p class="mt-3 mt-md-4 text-center">© 2023 WORKANY. Crafted by <a href="https://w3engineers.com/"
                                                                              class="customs-text-color" target="_blank">W3
              Engineers Ltd</a>.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-xl-8 d-none d-lg-block">
        <div class="min-vh-100 py-5 d-flex align-items-center">
          <div class="w-100">
            <h4 class="font-size-28 text-center px-3">Spend less time tracking and more time growing.</h4>
            <p class="text-center px-3">The all-in-one time tracker for teams.</p>
            <div class="d-flex justify-content-center">
              <img src="@/assets/images/bg/home.jpg" height="100%" width="70%" alt="login"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.customs-bg-color {
  background-color: #ff7c47 !important;
  border: none;
}
.customs-text-color {
  color: #ff7c47;
}

</style>
